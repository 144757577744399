var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "head" }, [
      _vm._m(0),
      _c("div", { staticClass: "time", attrs: { id: "showTime" } }, [
        _vm._v(_vm._s(_vm.setTime)),
      ]),
    ]),
    _c("div", { staticClass: "mainbox" }, [
      _c("ul", { staticClass: "clearfix" }, [
        _c("li", [
          _c(
            "div",
            {
              staticClass: "boxall",
              staticStyle: { height: "calc(25vh - 0.84rem)" },
            },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("平台放款情况")]),
              _c(
                "div",
                {
                  staticClass: "boxnav boxnavList pd2",
                  attrs: { id: "echarts4" },
                },
                [
                  _c("div", { staticClass: "boxnavItem" }, [
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("今日最新放款"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "boxnavItemContent gradient-text changfontsize",
                      },
                      [_vm._v(_vm._s(_vm.databoard.todayLoanAmount))]
                    ),
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("万元"),
                    ]),
                  ]),
                  _c("div", { staticClass: "boxnavItem" }, [
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("平台当前在贷"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "boxnavItemContent gradient-textblue changfontsize",
                      },
                      [_vm._v(_vm._s(_vm.databoard.inLoanAmount))]
                    ),
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("万元"),
                    ]),
                  ]),
                  _c("div", { staticClass: "boxnavItem" }, [
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("平台累计放贷"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "boxnavItemContent gradient-textblue changfontsize",
                      },
                      [_vm._v(_vm._s(_vm.databoard.accLoanAmount))]
                    ),
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("万元"),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "boxall",
              staticStyle: { height: "calc(25vh - 0.84rem)" },
            },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("平台收入情况")]),
              _c(
                "div",
                {
                  staticClass: "boxnav boxnavList pd2",
                  attrs: { id: "echarts4" },
                },
                [
                  _c("div", { staticClass: "boxnavItem" }, [
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("累计息费收"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "boxnavItemContent gradient-text changfontsize",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.databoard.accReceiptInterestFeeAmount)
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("万元"),
                    ]),
                  ]),
                  _c("div", { staticClass: "boxnavItem" }, [
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("累计服务费收"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "boxnavItemContent gradient-textblue changfontsize",
                      },
                      [_vm._v(_vm._s(_vm.databoard.accRetainedServiceAmount))]
                    ),
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("万元"),
                    ]),
                  ]),
                  _c("div", { staticClass: "boxnavItem" }, [
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("累计利息收"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "boxnavItemContent gradient-textblue changfontsize",
                      },
                      [_vm._v(_vm._s(_vm.databoard.accPaymentInterest))]
                    ),
                    _c("div", { staticClass: "boxnavItemContent" }, [
                      _vm._v("万元"),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "boxall",
              staticStyle: {
                height: "calc(50vh - 0.84rem)",
                "margin-top": "0.6rem !important",
              },
            },
            [
              _c("div", { staticClass: "alltitle" }, [
                _vm._v("平台店铺分布情况"),
              ]),
              _c(
                "div",
                { staticClass: "boxnav pd2", attrs: { id: "peiCharts" } },
                [
                  _c("pie-chart-three", {
                    attrs: {
                      "series-data": _vm.platformStoreCount,
                      platformStoreCount: _vm.platformStoreCount,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
        _c("li", [
          _c(
            "div",
            {
              staticClass: "boxall boxallCenter",
              staticStyle: { height: "calc(100vh - 2.2rem)" },
            },
            [
              _c("img", {
                staticClass: "global",
                attrs: { src: require("@/assets/bg/global.png"), alt: "" },
              }),
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _vm._m(6),
            ]
          ),
        ]),
        _c("li", [
          _c(
            "div",
            {
              staticClass: "boxall",
              staticStyle: { height: "calc(33.333vh - 0.84rem)" },
            },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("平台企业诊断")]),
              _c("div", { staticClass: "boxnav boxnavList pd2" }, [
                _c("div", { staticClass: "boxnavItemLong" }, [
                  _c("div", { staticClass: "boxnavItemContentLong" }, [
                    _vm._v("\n                平台企业数量\n                "),
                    _c(
                      "span",
                      {
                        staticClass: "gradient-text",
                        staticStyle: { "font-size": "0.3rem" },
                      },
                      [_vm._v(_vm._s(_vm.detail.enterpriseCount))]
                    ),
                  ]),
                  _c("div", { staticClass: "boxnavItemContentLong" }, [
                    _vm._v(
                      "\n                平台已服务店铺数\n                "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "gradient-textblue",
                        staticStyle: { "font-size": "0.3rem" },
                      },
                      [_vm._v(_vm._s(_vm.detail.servicedStoresCount || 0))]
                    ),
                  ]),
                  _c("div", { staticClass: "boxnavItemContentLong" }, [
                    _vm._v(
                      "\n                当前店铺平均在贷(万元)\n                "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "gradient-textblue",
                        staticStyle: { "font-size": "0.3rem" },
                      },
                      [_vm._v(_vm._s(_vm.detail.avgAmount))]
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "boxall",
              staticStyle: { height: "calc(33.333vh - 0.84rem)" },
            },
            [
              _c("div", { staticClass: "alltitle" }, [
                _vm._v("平台每日放款金额(元)"),
              ]),
              _c("div", {
                ref: "echarts5",
                staticClass: "boxnav",
                attrs: { id: "echarts5" },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "boxall",
              staticStyle: { height: "calc(33.333vh - 0.84rem)" },
            },
            [
              _c("div", { staticClass: "alltitle" }, [_vm._v("平台总收益IRR")]),
              _c("div", {
                ref: "loanAmountRef",
                staticClass: "boxnav",
                staticStyle: { width: "100%" },
                attrs: { id: "echarts6" },
              }),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { display: "flex", "padding-top": "0.2rem" } },
      [
        _c("img", {
          staticStyle: { width: "1rem" },
          attrs: {
            src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/bium.png",
          },
        }),
        _c("h1", { staticStyle: { "margin-top": "0.2rem" } }, [
          _vm._v("必米供应链大数据看板"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centerItem" }, [
      _c("div", { staticClass: "alltitle1" }, [_vm._v("Active模型指标")]),
      _c("div", { staticClass: "frosted_3 glass" }, [
        _vm._v("563 "),
        _c("span", { staticClass: "personword" }, [_vm._v("个")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centerItem" }, [
      _c("div", { staticClass: "alltitle1" }, [_vm._v("规则累计执行")]),
      _c("div", { staticClass: "frosted_3 glass" }, [
        _vm._v("776467"),
        _c("span", { staticClass: "personword" }, [_vm._v("次")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centerItem" }, [
      _c("div", { staticClass: "alltitle1" }, [_vm._v("Active Rules")]),
      _c("div", { staticClass: "frosted_3 glass" }, [
        _vm._v("2396"),
        _c("span", { staticClass: "personword" }, [_vm._v("条")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centerItem" }, [
      _c("div", { staticClass: "alltitle1" }, [_vm._v("指标命中次数")]),
      _c("div", { staticClass: "frosted_3 glass" }, [
        _vm._v("4872"),
        _c("span", { staticClass: "personword" }, [_vm._v("次")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centerItem" }, [
      _c("div", { staticClass: "alltitle1" }, [_vm._v("模型当前负载")]),
      _c("div", { staticClass: "frosted_3 glass" }, [_vm._v("37.82%")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "centerItem" }, [
      _c("div", { staticClass: "alltitle1" }, [_vm._v("AI推理Token")]),
      _c("div", { staticClass: "frosted_3 glass" }, [_vm._v("2.37M")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }