<template>
  <div class="content">
    <!-- <div class="loading">
      <div class="loadbox">
        <img src="images/loading.gif" />
        页面加载中...
      </div>
    </div> -->
    <div class="head">
     <div style="display: flex; padding-top: 0.2rem;">
      <img src="https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/bium.png" style="width: 1rem;  " />
      <h1 style="margin-top: 0.2rem;">必米供应链大数据看板</h1>
     </div>
      <div class="time" id="showTime">{{ setTime }}</div>
    </div>
    <div class="mainbox">
      <ul class="clearfix">
        <li>
          <div class="boxall" style="height: calc(25vh - 0.84rem)">
            <div class="alltitle">平台放款情况</div>
            <div class="boxnav boxnavList pd2" id="echarts4">
              <div class="boxnavItem">
                <div class="boxnavItemContent">今日最新放款</div>
                <div class="boxnavItemContent gradient-text changfontsize" >{{ databoard.todayLoanAmount }}</div>
                <div class="boxnavItemContent">万元</div>
              </div>
              <div class="boxnavItem">
                <div class="boxnavItemContent">平台当前在贷</div>
                <div class="boxnavItemContent gradient-textblue changfontsize">{{ databoard.inLoanAmount }}</div>
                <div class="boxnavItemContent">万元</div>
              </div>
              <div class="boxnavItem">
                <div class="boxnavItemContent">平台累计放贷</div>
                <div class="boxnavItemContent gradient-textblue changfontsize">{{ databoard.accLoanAmount }}</div>
                <div class="boxnavItemContent">万元</div>
              </div>
            </div>
          </div>
          <div class="boxall" style="height: calc(25vh - 0.84rem)">
            <div class="alltitle">平台收入情况</div>
            <div class="boxnav boxnavList pd2" id="echarts4">
              <div class="boxnavItem">
                <div class="boxnavItemContent">累计息费收</div>
                <div class="boxnavItemContent gradient-text changfontsize">{{ databoard.accReceiptInterestFeeAmount }}</div>
                <div class="boxnavItemContent">万元</div>
              </div>
              <div class="boxnavItem">
                <div class="boxnavItemContent">累计服务费收</div>
                <div class="boxnavItemContent gradient-textblue changfontsize">{{ databoard.accRetainedServiceAmount }}</div>
                <div class="boxnavItemContent">万元</div>
              </div>
              <div class="boxnavItem">
                <div class="boxnavItemContent">累计利息收</div>
                <div class="boxnavItemContent gradient-textblue changfontsize">{{ databoard.accPaymentInterest }}</div>
                <div class="boxnavItemContent">万元</div>
              </div>
            </div>
          </div>
          <div class="boxall" style="height: calc(50vh - 0.84rem);margin-top: 0.6rem !important;">
            <div class="alltitle">平台店铺分布情况</div>
            <div class="boxnav pd2" id="peiCharts">
              <!-- <pie-chart :series-data="dataList" :extra-option="extraOption"></pie-chart> -->

              <pie-chart-three
                :series-data="platformStoreCount"
                :platformStoreCount="platformStoreCount"></pie-chart-three>
            </div>
          </div>
        </li>
        <li>
          <!-- <div class="boxall" style="height: calc(20vh - 0.45rem)">
            <ul class="row h100 clearfix">
              <li class="col-6">
                <div class="sqzs h100">
                  <p>业绩总览</p>
                  <h1>
                    <span>30500</span>
                    万
                  </h1>
                </div>
              </li>
              <li class="col-6">
                <ul class="row h100 clearfix">
                  <li class="col-4">
                    <div class="tit01">标题名称</div>
                    <div class="piebox" id="pe01"></div>
                  </li>
                  <li class="col-4">
                    <div class="tit01">标题名称</div>
                    <div class="piebox" id="pe02"></div>
                  </li>
                  <li class="col-4">
                    <div class="tit01">标题名称</div>
                    <div class="piebox" id="pe03"></div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="boxall" style="height: calc(38vh - 0.45rem)">
            <div class="alltitle">标题名称</div>
            <div class="boxnav" id="echarts1"></div>
          </div>
          <div class="boxall" style="height: calc(42vh - 0.45rem)">
            <div class="alltitle">标题名称</div>
            <div class="boxnav" id="echarts2"></div>
          </div> -->

          <div class="boxall boxallCenter" style="height: calc(100vh - 2.2rem)">
            <img :src="require('@/assets/bg/global.png')" alt="" class="global" />
            <div class="centerItem">
              <div class="alltitle1">Active模型指标</div>
              <div class="frosted_3 glass">563 <span class="personword">个</span> </div>
            </div>
            <div class="centerItem">
              <div class="alltitle1">规则累计执行</div>
              <div class="frosted_3 glass">776467<span class="personword">次</span></div>
            </div>
            <div class="centerItem">
              <div class="alltitle1">Active Rules</div>
              <div class="frosted_3 glass">2396<span class="personword">条</span></div>
            </div>
            <div class="centerItem">
              <div class="alltitle1">指标命中次数</div>
              <div class="frosted_3 glass">4872<span class="personword">次</span></div>
            </div>
            <div class="centerItem">
              <div class="alltitle1">模型当前负载</div>
              <div class="frosted_3 glass">37.82%</div>
            </div>
            <div class="centerItem">
              <div class="alltitle1">AI推理Token</div>
              <div class="frosted_3 glass">2.37M</div>
            </div>
          </div>
        </li>
        <li>
          <div class="boxall" style="height: calc(33.333vh - 0.84rem)">
            <div class="alltitle">平台企业诊断</div>
            <div class="boxnav boxnavList pd2">
              <div class="boxnavItemLong">
                <div class="boxnavItemContentLong">
                  平台企业数量
                  <span class="gradient-text" style="font-size: 0.3rem;">{{ detail.enterpriseCount}}</span>
                </div>
                <div class="boxnavItemContentLong">
                  平台已服务店铺数
                  <span class="gradient-textblue" style="font-size: 0.3rem;">{{ detail.servicedStoresCount  || 0}}</span>
                </div>
                <div class="boxnavItemContentLong">
                  当前店铺平均在贷(万元)
                  <span class="gradient-textblue" style="font-size: 0.3rem;">{{ detail.avgAmount }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="boxall" style="height: calc(33.333vh - 0.84rem)">
            <div class="alltitle">平台每日放款金额(元)</div>
            <div class="boxnav" id="echarts5" ref="echarts5"></div>
          </div>
          <div class="boxall" style="height: calc(33.333vh - 0.84rem)">
            <div class="alltitle">平台总收益IRR</div>
            <div class="boxnav" id="echarts6" ref="loanAmountRef"  style="width: 100%; ">
              <!-- <pie-chart-three></pie-chart-three> -->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import * as Echarts from 'echarts'
import dayjs from 'dayjs'
import PieChart from './components/piechart.vue'
import PieChartThree from './components/PieChartThree.vue'
import { getDashboard } from '@/api/login.js'
export default {
  components: {
    PieChart,
    PieChartThree,
  },
  data() {
    return {
      
      dataList: [
        {
          name: '西瓜',
          value: 20,
          itemStyle: {
            color: '#22c4ff',
          },
        },
        {
          name: '橘子',
          value: 13,
          itemStyle: {
            color: '#aaff00',
          },
        },
        {
          name: '杨桃',
          value: 33,
          itemStyle: {
            color: '#aaff00',
          },
        },
      ],
      extraOption: {
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          top: 30,
          right: 10,
          left: 20,
          bottom: 20, //间距自适应
        },
        textStyle: {
          color: 'green',
          fontSize: 10, //字体自适应
        },
      },
      databoard: {},
      platformText: {
        10: '抖音',
        11: '快手',
        12: '拼多多',
        13: '视频号',
        14: '美团',
        15: '抖音小时达',
      },
      platformStoreCount: [],
      platformIcon: {
        10: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/douyin.png',
        11: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/kuaishou.png',
        12: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/pdd.png',
        13: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/video.png',
        14: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/meituan.png',
        15: 'https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/miniApp/shop/douyin.png',
      },
      type: 0,
      statisticsFee: {},
      statisticsFeeInfo: {},
      today: dayjs().format('YYYY-MM-DD'),
      loanAmount: {},
      paymentAmount: {},
      loanAmountType: 7,
      paymentAmountType: 30,
      detail: {},
      setTime: '', //显示时间
      t: null, //定时器s
    }
  },
  beforeCreate() {
    let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let html = document.getElementsByTagName('html')[0]
    html.style.fontSize = `${clientWidth / 20}px`
  },
  created() {
   
    window.addEventListener('resize', () => {
      let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      let html = document.getElementsByTagName('html')[0]
      html.style.fontSize = `${clientWidth / 20}px`
    })
  },
  mounted() {
  
    let self = this
    this.t = setTimeout(() => {
      self.getTime()
    }, 1000) //開始运行
    this.init()

    this.$nextTick(() => {
      try {
        self.echarts_5()
      } catch (error) {
        console.log('🚀 ~ this.$nextTick ~ error:', error)
      }
    })
  },
  unmounted() {
    window.removeEventListener('resize')
    let html = document.getElementsByTagName('html')[0]
    html.style.fontSize = 'auto'
    let body = document.getElementsByTagName('body')[0]
    body.style.fontSize = 'auto'
  },
  methods: {
    formatNumber(num) {
      if (num && num > 0) {
        num = Number(num)
        return (num / 10000).toFixed(2)
      } else {
        return 0
      }
    },
    
    
    init() {
      let self = this
      self
        .doPost('/bium-portal/controller/fin-ops/databoard/databoard', {
          productType: 0,
        })
        .then((res) => {
          console.log('res', res)
          if (res.data.errCode == 1000) {
            self.databoard = res.data.result
          }
        })
      self.doPost('/bium-portal/controller/fin-ops/statistics/info', {}).then((res) => {
        console.log('res1', res)
        let result = res.data.result
        self.detail = result
        const dom = self.$refs.shopPlatformRef
        let platformStoreCount = []
        let keys = Object.keys(result.platformStoreCount)
        keys.forEach((item) => {
          console.log('item', self.platformText[item],);
          
          platformStoreCount.push({
            value: result.platformStoreCount[item],
            name: self.platformText[item],
            id: item,
          })
        })
        
        
        self.platformStoreCount = platformStoreCount
        console.log('platformStoreCount', this.platformStoreCount);
        
        self.onLoadPie(dom, platformStoreCount)
        self.statisticsFee = result.statisticsFeeDTOMap
        self.onChangeType(0)
        self.loanAmount = result.loanAmountMap
        self.paymentAmount = result.paymentAmountMap
        self.onChangePaymentAmount(7)
        self.onChangeLoanAmount(7)
      })
    },

    getTime() {
      clearTimeout(this.t) //清除定时器
      let dt = new Date()
      let y = dt.getFullYear()
      let mt = dt.getMonth() + 1
      let day = dt.getDate()
      let h = dt.getHours() //获取时
      let m = dt.getMinutes() //获取分
      let s = dt.getSeconds() //获取秒
      this.t = null
      this.setTime =
        y +
        '/' +
        this.Appendzero(mt) +
        '/' +
        this.Appendzero(day) +
        ' ' +
        this.Appendzero(h) +
        ':' +
        this.Appendzero(m) +
        ':' +
        this.Appendzero(s) +
        ''

      this.t = setTimeout(() => {
        this.getTime()
      }, 1000) //设定定时器，循环运行
    },
    Appendzero(obj) {
      if (obj < 10) return '0' + '' + obj
      else return obj
    },
    onChangePaymentAmount(key) {
      let self = this
      self.paymentAmountType = key
      let paymentAmountX = []
      let paymentAmountY = []
      let paymentAmountDom = self.$refs.paymentAmountRef
      let paymentAmountMapKeys = Object.keys(self.paymentAmount)
      let keys = []
      paymentAmountMapKeys.forEach((item) => {
        keys.push(new Date(item).getTime())
      })
      keys.sort()
      let val = []
      keys.forEach((item) => {
        if (dayjs(self.today).diff(dayjs(item).format('YYYY-MM-DD'), 'day') <= self.paymentAmountType) {
          val.push(dayjs(item).format('YYYY-MM-DD'))
        }
      })
      val.forEach((item) => {
        paymentAmountX.push(dayjs(self.paymentAmount[item].days).format('MM.DD'))
        paymentAmountY.push(self.paymentAmount[item].amount)
      })
      self.onLoadLine(paymentAmountDom, paymentAmountX, paymentAmountY)
    },
    onChangeLoanAmount(key) {
      let self = this
      self.loanAmountType = key
      let loanAmountDom = self.$refs.loanAmountRef
      let loanAmountMapKeys = Object.keys(self.loanAmount)
      let loanAmountX = []
      let loanAmountY = []
      let keys = []
      loanAmountMapKeys.forEach((item) => {
        keys.push(new Date(item).getTime())
      })
      keys.sort()
      let val = []
      keys.forEach((item) => {
        if (dayjs(self.today).diff(dayjs(item).format('YYYY-MM-DD'), 'day') <= self.loanAmountType) {
          val.push(dayjs(item).format('YYYY-MM-DD'))
        }
      })
      console.log(val,11111111);
      
      val.forEach((item) => {
        loanAmountX.push(dayjs(self.loanAmount[item].days).format('MM.DD'))
        loanAmountY.push(self.loanAmount[item].amount)
      })
      console.log(loanAmountX,loanAmountY);
      
      self.showLineChart(loanAmountDom, loanAmountX, loanAmountY)
      self.echarts_5(loanAmountDom, loanAmountX, loanAmountY)
    },
    onChangeType(key) {
      let self = this
      self.type = key
      const plate = self.$refs.shopPlateRef
      let data = []
      let statisticsFee = self.statisticsFee[self.type]
      self.statisticsFeeInfo = statisticsFee
      let serviceFee = [
        {
          value: statisticsFee.serviceFee,
          name: '服务费收入',
        },
      ]
      let interestFee = [
        {
          value: statisticsFee.interestFee,
          name: '利息收入',
        },
      ]
      data = data.concat(serviceFee).concat(interestFee)
      self.onLoadPie(plate, data)
    },
    // onChangePaymentAmount() {},
    onLoadPie(dom, data) {
      this.$nextTick(() => {
        let option = {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: '0%',
            right: '0',
            orient: 'vertical',
          },
          label: {
            formatter: '{b}\n{d}%}',
          },
          series: [
            {
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              padAngle: 5,
              itemStyle: {
                borderRadius: 10,
              },
              data: data,
            },
          ],
        }
        Echarts.init(dom).dispose()
        const Chart = Echarts.init(dom)
        Chart.setOption(option)
        window.addEventListener('resize', () => {
          Chart.resize()
        })
      })
    },
    onLoadLine(dom, x, y) {
      console.log('🚀 ~ onLoadLine ~ dom:', dom, x, y)
      this.$nextTick(() => {
        let option = {
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              let json = params[0]
              return json.data
            },
            axisPointer: {
              animation: false,
            },
          },
          xAxis: {
            type: 'category',
            data: x,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: y,
              type: 'line',
              smooth: true,
            },
          ],
        }
        Echarts.init(dom).dispose()
        const Chart = Echarts.init(dom)
        Chart.setOption(option)
        window.addEventListener('resize', () => {
          Chart.resize()
        })
      })
    },

    echarts_5(dom, x, y) {
      console.log(dom,x,y);
      
      this.$echarts.init(this.$refs.echarts5).dispose()
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(this.$refs.echarts5)

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
        },
        grid: {
          top: '10%',
          bottom: '20',
          right: '10%',
          left: '10%',
        },
        legend: {
          show: false,
        },
        xAxis: [
          {
            type: 'category',
            data: x,
            axisLine: { lineStyle: { color: 'rgba(255,255,255,.1)' }, show: true },
            axisLabel: {
              textStyle: { color: 'rgba(255,255,255,.7)', fontSize: '14' },
              show: true,
            },
          },
        ],
        yAxis: [
          {
            show: false,
            type: 'value',
            name: '单位1',
            // splitLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              show: true,
              color: 'rgba(255,255,255,.6)',
            },
            axisLine: { lineStyle: { color: 'rgba(255,255,255,.1)' } }, //左线色
          },
          {
            type: 'value',
            name: '单位2',
            show: false,
            // axisTick: { show: false },
            // axisLabel: {
            //   show: true,
            //   formatter: '{value} %',
            //   color: 'rgba(255,255,255,.6)',
            // },
            // axisLine: { lineStyle: { color: 'rgba(255,255,255,.1)' } }, //右线色
            // splitLine: {
            //   show: true,
            //   lineStyle: { color: 'rgba(255,255,255,.1)' },
            // }, //x轴线
            splitLine: { show: false },
            axisTick: { show: false },
          },
        ],
        series: [
          {
            name: '店铺交易总额',
            type: 'bar',
            data: y,
            barWidth: '10%',
            itemStyle: {
              normal: {
                barBorderRadius: 15,
                color: new Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#fccb05',
                  },
                  {
                    offset: 1,
                    color: '#f5804d',
                  },
                ]),
              },
            },
            barGap: '0',
          },
          // {
          //   name: '字段2',
          //   type: 'line',
          //   yAxisIndex: 1,
          //   data: [0, 11.48, 18.0, 25.65],
          //   lineStyle: {
          //     normal: {
          //       width: 2,
          //     },
          //   },
          //   itemStyle: {
          //     normal: {
          //       color: '#ff3300',
          //     },
          //   },
          //   smooth: true,
          // },
        ],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },

    showLineChart(dom, x, y) {
      this.$echarts.init(dom).dispose()
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(dom)

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' },
          // formatter:'{c}' ,
        },
        grid: {
          top: '10%',
          bottom: '-20',
          right: '0%',
          left: '-5%',
          containLabel: false,
        },
        legend: {
          show: false,
        },
        xAxis: [
          {
           
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              formatter: '{value} ',
              rotate: -90,
              textStyle: {
                color: 'rgba(255,255,255,.6)',
                fontSize: 14,
              },
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.1)',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
            },
            data: x,
          },
          {
            axisPointer: { show: true },
            axisLine: { show: true },
            position: 'bottom',
            offset: 20,
          },
        ],

        yAxis: [
          {
            show: false,
            type: 'value',
            axisTick: { show: false },
            // splitNumber: 6,
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.1)',
              },
            },
            axisLabel: {
              formatter: '{value} %',
              textStyle: {
                color: 'rgba(255,255,255,.6)',
                fontSize: 14,
              },
            },

            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.1)',
              },
            },
          },
        ],
        series: [
        // {
        //     name: '平台总收益',
        //     type: 'line',
        //     smooth: true,
        //     symbol: 'circle',
        //     symbolSize: 5,
        //     showSymbol: false,
        //     lineStyle: {
        //       normal: {
        //         color: 'rgba(228, 228, 126, 1)',
        //         width: 2,
        //       },
        //     },
        //     areaStyle: {
        //       normal: {
        //         color: new Echarts.graphic.LinearGradient(
        //           0,
        //           0,
        //           0,
        //           1,
        //           [
        //             {
        //               offset: 0,
        //               color: 'rgba(228, 228, 126, .2)',
        //             },
        //             {
        //               offset: 1,
        //               color: 'rgba(228, 228, 126, 0)',
        //             },
        //           ],
        //           false,
        //         ),
        //         shadowColor: 'rgba(0, 0, 0, 0.1)',
        //       },
        //     },
        //     itemStyle: {
        //       normal: {
        //         color: 'rgba(228, 228, 126, 1)',
        //         borderColor: 'rgba(228, 228, 126, .1)',
        //         borderWidth: 12,
        //       },
        //     },
        //     data: y,
        //   },
          {
            name: '总利息当前年化IRR',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                color: 'rgba(255, 168, 28, 1)',
                width: 2,
              },
            },
            areaStyle: {
              normal: {
                color: new Echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 128, 128,.2)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 128, 128, 0)',
                    },
                  ],
                  false,
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
              },
            },
            itemStyle: {
              normal: {
                color: 'rgba(255, 168, 28, 1)',
                borderColor: 'rgba(97, 238, 28, .1)',
                borderWidth: 12,
              },
            },
            data: this.databoard.interestAnnualizedIrrList,
          },
          {
            name: '总息费当前年化IRR',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                color: 'rgba(25, 198, 8, 1)',
                width: 2,
              },
            },
            areaStyle: {
              normal: {
                color: new Echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 128, 128,.2)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 128, 128, 0)',
                    },
                  ],
                  false,
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
              },
            },
            itemStyle: {
              normal: {
                color: 'rgba(25, 198, 8, 1)',
                borderColor: 'rgba(255, 128, 128, .1)',
                borderWidth: 12,
              },
            },
            data: this.databoard.interestFeeAnnualizedIrrList,
          },
          {
            name: '总服务费当前年化IRR',
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                color: 'rgba(255, 128, 128, 1)',
                width: 2,
              },
            },
            areaStyle: {
              normal: {
                color: new Echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 128, 128,.2)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 128, 128, 0)',
                    },
                  ],
                  false,
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
              },
            },
            itemStyle: {
              normal: {
                color: 'rgba(255, 128, 128, 1)',
                borderColor: 'rgba(255, 128, 128, .1)',
                borderWidth: 12,
              },
            },
            data: this.databoard.serviceFeeAnnualizedIrrList,
          },
        ],
      }
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import url('./index.scss');


</style>
