var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "water-eval-container" }, [
    _c("div", {
      ref: "cityGreenLand-charts",
      staticClass: "cityGreenLand-charts",
      attrs: { id: "cityGreenLand-charts" },
    }),
    _c(
      "div",
      {
        staticClass: "platformList",
        class: [_vm.platformStoreCount.length > 6 ? "overflowYAuto" : ""],
      },
      _vm._l(_vm.platformStoreCount, function (item, index) {
        return _c("div", { key: item.id, staticClass: "platformItem" }, [
          _c("div", {
            style: {
              flexShrink: 0,
              backgroundColor: _vm.color[index],
              width: "5px",
              height: "5px",
              marginRight: "4px",
            },
          }),
          _c("img", {
            staticClass: "platformIcon",
            staticStyle: {
              "background-color": "aliceblue",
              "border-radius": "50%",
            },
            attrs: { src: _vm.platformIcon[item.id] },
          }),
          _vm._v("  \n      "),
          _c("div", { staticStyle: { width: "40%", "font-size": "0.13rem" } }, [
            _vm._v(_vm._s(_vm.platformText[item.id])),
          ]),
          _vm.platformStoreCount.length > 0 && _vm.totalNum > 0
            ? _c(
                "div",
                [
                  _c("pie-chart-simple", {
                    attrs: {
                      totalNum: _vm.totalNum,
                      item: item,
                      color: _vm.color[index],
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }