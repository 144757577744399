import { fitChartSize } from '@/utils/dataUtil.js'
export const basicOption = {
  title: {
    text: '某某手机数据',
    subtext: '来自xx公司',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
  },
  legend: {
    orient: 'vertical',
    left: 'left',
    textStyle: {
      color: 'RGB(254,66,7)',
      fontSize: fitChartSize(10), //使用字体自适应
    },
  },
  series: [
    {
      name: '手机购买占比',
      type: 'pie',
      radius: '50%',
      radius: ['20%', '50%'],
      center: ['50%', '50%'],
      label: {
        // show: false,
        position: 'center',
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      data: [], // 这里在使用的时候会被业务数据替换
    },
  ],
}

export const barBasicOption = {
  title: {
    text: '某某手机数据',
    subtext: '来自xx公司',
    left: 'center',
  },
  tooltip: {
    trigger: 'item',
  },
  legend: {
    orient: 'vertical',
    left: 'left',
    textStyle: {
      color: 'RGB(254,66,7)',
      fontSize: fitChartSize(10), //使用字体自适应
    },
  },
  series: [
    {
      name: '手机购买占比',
      type: 'bar',
      label: {
        // show: false,
        position: 'center',
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
      data: [], // 这里在使用的时候会被业务数据替换
    },
  ],
}

export const simplePieBasicOption = {
  title: {
    text: '0',
    x: 'center',
    y: 'center',
    textStyle: {
      fontWeight: 'normal',
      color: '#fff',
      fontSize: '18',
    },
  },
  color: 'rgba(255,255,255,.3)',
  series: [
    {
      name: 'Line 1',
      type: 'pie',
      clockWise: true,
      radius: ['65%', '80%'],
      itemStyle: {
        normal: {
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
      },
      hoverAnimation: false,
      data: [
        {
          value: '0',
          name: '已使用',
          itemStyle: {
            normal: {
              color: '#eaff00',
              label: {
                show: false,
              },
              labelLine: {
                show: false,
              },
            },
          },
        },
        {
          name: '未使用',
          value: 100 - 0,
        },
      ],
    },
  ],
}
