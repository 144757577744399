var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "itemBox" }, [
                _c("img", {
                  staticClass: "itemBox_icon",
                  attrs: {
                    src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon1.png",
                  },
                }),
                _c("div", { staticClass: "itemBox_info" }, [
                  _c("p", { staticClass: "itemBox_info_title" }, [
                    _vm._v("平台企业数量"),
                  ]),
                  _c("p", { staticClass: "itemBox_info_tip" }, [
                    _vm._v(_vm._s(_vm.detail.enterpriseCount || 0)),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "itemBox" }, [
                _c("img", {
                  staticClass: "itemBox_icon",
                  attrs: {
                    src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon2.png",
                  },
                }),
                _c("div", { staticClass: "itemBox_info" }, [
                  _c("p", { staticClass: "itemBox_info_title" }, [
                    _vm._v("一般企业"),
                  ]),
                  _c("p", { staticClass: "itemBox_info_tip" }, [
                    _vm._v(_vm._s(_vm.detail.generalEnterpriseCount || 0)),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "itemBox" }, [
                _c("img", {
                  staticClass: "itemBox_icon",
                  attrs: {
                    src: "https://bium-prod.oss-cn-hangzhou.aliyuncs.com/front_file/backEnd/icon3.png",
                  },
                }),
                _c("div", { staticClass: "itemBox_info" }, [
                  _c("p", { staticClass: "itemBox_info_title" }, [
                    _vm._v("个体工商户"),
                  ]),
                  _c("p", { staticClass: "itemBox_info_tip" }, [
                    _vm._v(_vm._s(_vm.detail.individualEnterpriseCount || 0)),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                { staticClass: "box platform_shop" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("平台店铺数")]),
                  _vm._l(_vm.platformStoreCount, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "platform" },
                      [
                        _c("img", {
                          staticClass: "platformIcon",
                          attrs: { src: _vm.platformIcon[item.id] },
                        }),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.platformText[item.id]) +
                            "\n            "
                        ),
                        _c("label", [_vm._v(_vm._s(item.value || 0))]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "title" }, [_vm._v("店铺各平台占比")]),
                _c("div", {
                  ref: "shopPlatformRef",
                  staticClass: "canvas-container",
                }),
              ]),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c("div", { staticClass: "box platform_num" }, [
                _c("div", { staticClass: "title" }, [_vm._v("平台店铺数")]),
                _c("div", { staticStyle: { "margin-bottom": "32px" } }, [
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v("平台已服务店铺数"),
                  ]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.detail.servicedStoresCount || 0)),
                  ]),
                ]),
                _c("div", { staticStyle: { "margin-bottom": "32px" } }, [
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v("当前融资店铺数"),
                  ]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.detail.repaymentStoresCount || 0)),
                  ]),
                ]),
                _c("div", [
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v("当前店铺平均在贷"),
                  ]),
                  _c("p", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.detail.avgAmount || 0)),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "box" }, [
                _c(
                  "div",
                  { staticClass: "title", staticStyle: { height: "30px" } },
                  [
                    _vm._v("\n            平台每日贷款余额\n            "),
                    _c("div", { staticClass: "title-action" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.loanAmountType == 7 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeLoanAmount(7)
                            },
                          },
                        },
                        [_vm._v("\n                7天\n              ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.loanAmountType == 30 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeLoanAmount(30)
                            },
                          },
                        },
                        [_vm._v("\n                30天\n              ")]
                      ),
                    ]),
                  ]
                ),
                _c("div", {
                  ref: "loanAmountRef",
                  staticClass: "canvas-container",
                }),
              ]),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "box" }, [
                _c(
                  "div",
                  { staticClass: "title", staticStyle: { height: "30px" } },
                  [
                    _vm._v("\n            平台每日放款余额\n            "),
                    _c("div", { staticClass: "title-action" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.paymentAmountType == 7 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangePaymentAmount(7)
                            },
                          },
                        },
                        [_vm._v("\n                7天\n              ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.paymentAmountType == 30 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangePaymentAmount(30)
                            },
                          },
                        },
                        [_vm._v("\n                30天\n              ")]
                      ),
                    ]),
                  ]
                ),
                _c("div", {
                  ref: "paymentAmountRef",
                  staticClass: "canvas-container",
                }),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "boxInfo" }, [
                _c("p", { staticClass: "subtitle" }, [
                  _vm._v("平台累计信用总额"),
                ]),
                _c("p", { staticClass: "values" }, [
                  _vm._v(_vm._s(_vm.detail.creditAmount || 0)),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 12 } }, [
              _c("div", { staticClass: "boxInfoPrice" }, [
                _c("p", { staticClass: "subtitle" }, [_vm._v("平台当前贷")]),
                _c("p", { staticClass: "values" }, [
                  _vm._v(_vm._s(_vm.detail.repaymentAmount || 0)),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb20" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                {
                  staticClass: "bg1",
                  staticStyle: { "margin-bottom": "14px" },
                },
                [
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v("今日放款金额"),
                  ]),
                  _c("p", { staticClass: "values" }, [
                    _vm._v(_vm._s(_vm.detail.todayAmount || 0)),
                  ]),
                ]
              ),
              _c("div", { staticClass: "bg2" }, [
                _c("p", { staticClass: "subtitle" }, [
                  _vm._v("平台累计放款金额"),
                ]),
                _c("p", { staticClass: "values" }, [
                  _vm._v(_vm._s(_vm.detail.totalAmount || 0)),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 16 } }, [
              _c("div", { staticClass: "box" }, [
                _c(
                  "div",
                  { staticClass: "title", staticStyle: { height: "30px" } },
                  [
                    _vm._v("\n            平台收入\n            "),
                    _c("div", { staticClass: "title-action" }, [
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.type == 0 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType(0)
                            },
                          },
                        },
                        [_vm._v("\n                今天\n              ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.type == 7 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType(7)
                            },
                          },
                        },
                        [_vm._v("\n                7天\n              ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "title-action-item",
                          class: _vm.type == 30 ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.onChangeType(30)
                            },
                          },
                        },
                        [_vm._v("\n                30天\n              ")]
                      ),
                    ]),
                  ]
                ),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c("div", {
                    ref: "shopPlateRef",
                    staticClass: "canvas-container",
                    staticStyle: { width: "50%" },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "50%",
                        "padding-left": "20px",
                        "box-sizing": "border-box",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "35px 0",
                            "border-bottom": "1px solid #EEEEEE",
                          },
                        },
                        [
                          _c("p", { staticClass: "full_title" }, [
                            _vm._v("利息总收入"),
                          ]),
                          _c("p", { staticClass: "full_desc" }, [
                            _vm._v(
                              _vm._s(_vm.statisticsFeeInfo.totalFee || "0")
                            ),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "flexBox" }, [
                        _c("div", { staticClass: "flexBoxItem" }, [
                          _c("p", { staticClass: "full_title" }, [
                            _vm._v("服务费收入"),
                          ]),
                          _c("p", { staticClass: "full_desc" }, [
                            _vm._v(
                              _vm._s(_vm.statisticsFeeInfo.serviceFee || "0")
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "flexBoxItem" }, [
                          _c("p", { staticClass: "full_title" }, [
                            _vm._v("利息收入"),
                          ]),
                          _c("p", { staticClass: "full_desc" }, [
                            _vm._v(
                              _vm._s(_vm.statisticsFeeInfo.interestFee || "0")
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb20 infos" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 20 } },
          [
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                {
                  staticClass: "full",
                  staticStyle: { "padding-left": "18px" },
                },
                [
                  _c("p", { staticClass: "full_title" }, [
                    _vm._v("当前总利息IRR"),
                  ]),
                  _c("p", { staticClass: "full_desc" }, [
                    _vm._v(
                      _vm._s(_vm.databoard.interestAnnualizedIrr || 0) + "%"
                    ),
                  ]),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                {
                  staticClass: "full",
                  staticStyle: { "padding-left": "18px" },
                },
                [
                  _c("p", { staticClass: "full_title" }, [
                    _vm._v("当前服务费IRR"),
                  ]),
                  _c("p", { staticClass: "full_desc" }, [
                    _vm._v(
                      _vm._s(_vm.databoard.serviceFeeAnnualizedIrr || 0) + "%"
                    ),
                  ]),
                ]
              ),
            ]),
            _c("el-col", { attrs: { span: 8 } }, [
              _c(
                "div",
                {
                  staticClass: "full",
                  staticStyle: { "padding-left": "18px" },
                },
                [
                  _c("p", { staticClass: "full_title" }, [
                    _vm._v("当前利息IRR"),
                  ]),
                  _c("p", { staticClass: "full_desc" }, [
                    _vm._v(
                      _vm._s(_vm.databoard.interestFeeAnnualizedIrr || 0) + "%"
                    ),
                  ]),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }