<template>
  <h3 v-if="isSeriesEmpty">暂无数据</h3>
  <div v-else class="chart"></div>
</template>

<script>
import * as Echarts from 'echarts'
import ResizeListener from 'element-resize-detector'
import { merge, isEmpty } from 'lodash'
import { simplePieBasicOption } from './defaultOption.js'
// import { pieChartColor } from './../color.js'

export default {
  name: 'PieChartSimple',
  props: {
    // 正常的业务数据，对应echarts饼图配置中series[0].data
    totalNum: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      required: true,
      default: {},
    },
    color: {
      type: String,
      default: '',
    },
    // 正常的业务数据，对应echarts饼图配置中series[0].data
    seriesData: {
      type: Array,
      required: false,
      default: () => [],
    },
    // 表示需要特殊定制的配置
    // 一般UI会规定一个统一的设计规范（比如颜色，字体，图例格式，位置等）
    // 但不排除某个图标会和设计规范不同，需要特殊定制样式，所以开放这个配置，增强灵活性
    extraOption: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chart: null,
      option: {
        title: {
          text: '0',
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#fff',
            fontSize: '18',
          },
        },
        color: 'rgba(255,255,255,.3)',
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: true,
            radius: ['65%', '80%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: '0',
                name: '已使用',
                itemStyle: {
                  normal: {
                    color: '#eaff00',
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: '未使用',
                value: 100 - 0,
              },
            ],
          },
        ],
      },
    }
  },

  computed: {
    isSeriesEmpty() {
      return false //isEmpty(this.seriesData) || this.seriesData.every((item) => !item.value)
    },
  },

  watch: {
    item: {
      deep: true,
      handler() {
        this.updateChartView()
      },
    },
  },

  mounted() {
    this.$nextTick(function () {
      this.chart = Echarts.init(this.$el)
      this.updateChartView()
      window.addEventListener('resize', this.handleWindowResize)
      this.addChartResizeListener()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    /* 合并配置项和数据，对于需要自定义的配置项以及数据，使用merge函数将其合并为一个option */

    assembleDataToOption() {
      // 这部分的图例formatter取决于UI要求，如果你的项目中不需要，就可以不写formatter
      // 由于echarts版本的迭代，这里的写法也有稍许改变
      // const formatter = (name) => {
      //   const total = this.seriesData.reduce((acc, cur) => acc + cur.value, 0)
      //   const data = this.seriesData.find((item) => item.name === name) || {}
      //   const percent = data.value ? `${Math.round((data.value / total) * 100)}%` : '0%'

      //   return `${name} ${percent}`
      // }

      return merge(
        {},
        simplePieBasicOption,
        // { color: ['#fe883a', '#2d90d1', '#f75981', '#90e2a9'] },
        // {
        //   legend: { formatter },
        //   series: [{ data: this.seriesData }],
        // },
        this.extraOption,
      )
    },
    /**
     * 对chart元素尺寸进行监听，当发生变化时同步更新echart视图
     */
    addChartResizeListener() {
      const instance = ResizeListener({
        strategy: 'scroll',
        callOnAdd: true,
      })

      instance.listenTo(this.$el, () => {
        if (!this.chart) return
        this.chart.resize()
      })
    },

    /**
     * 更新echart视图
     */
    updateChartView() {
      if (!this.chart) return

      try {
        this.option.title.text = this.item.value
        this.option.series[0].data[0].value = this.item.value
        this.option.series[0].data[1].value = this.totalNum - this.item.value
      } catch (error) {
        console.log('🚀 ~ mounted ~ error:', error)
      }

      let options = {
        title: {
          text: this.item.value,
          x: 'center',
          y: 'center',
          textStyle: {
            fontWeight: 'normal',
            color: '#fff',
            fontSize: '18',
          },
        },
        color: 'rgba(255,255,255,.3)',
        series: [
          {
            name: '',
            type: 'pie',
            clockWise: true,
            radius: ['65%', '80%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
              },
            },
            hoverAnimation: false,
            data: [
              {
                value: this.item.value,
                name: '已使用',
                itemStyle: {
                  normal: {
                    color: this.color,
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: '未使用',
                value: this.totalNum - this.item.value,
              },
            ],
          },
        ],
      }

      // const fullOption = this.assembleDataToOption()
      this.chart.setOption(options, true)
    },

    /**
     * 当窗口缩放时，echart动态调整自身大小
     */
    handleWindowResize() {
      if (!this.chart) return
      this.chart.resize()
    },
  },
}
</script>

<style scoped="scoped" lang="scss">
.chart {
  width: 50px;
  height: 50px;
}
</style>
